import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import { FUNCTION_CODE } from "@/constant/config";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { debounce } from 'vue-debounce';
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import moment from "moment";
import CaretTop from "../../views/components/Icon/CaretTop.vue";
import CaretBottom from "../../views/components/Icon/CaretBottom.vue";
const queryString = require('query-string');

export default {

    extends: baseComponent,

    components: {

    },

    data() {
        return {
            request: {
                action: 1
            }
        };
    },

    props: {
        element: {
            type: Number,
            default: null
        },
    },

    computed: {
        // ...mapState({
        //     listStaff: state => state.staffs,
        //     listServicePack: state => state.common.listServicePack,
        //     decentralization: state => state.auth.decentralization,
        // }),
    },

    created() {

    },

    methods: {

    }
}