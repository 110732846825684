import axios from "axios";
import { fixedRentalConfig, EXECUTE_RESULT } from "@/constant/config";
const queryString = require('query-string');

export default {

    // Get fixed rental
    getFixedRental: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${fixedRentalConfig.getFixedRentalUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Get fixed rental details
    getFixedRentalDetails: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: `${fixedRentalConfig.getFixedRentalDetailsUrl}?${request}`,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Get machine list by username
    getMachineListByUsername: function (request) {
        console.log("request", request)
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                url: fixedRentalConfig.getMachineListByUsernameUrl.format(request.username, request.fixedRentalId),
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Fixed rental create new
    fixedRentalCreateNew: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: fixedRentalConfig.fixedRentalCreateNewUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Fixed rental edit
    fixedRentalEdit: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: fixedRentalConfig.fixedRentalEditUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // Fixed rental ___ CHANGE IMAGE
    fixedRentalChangeImage: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: fixedRentalConfig.fixedRentalChangeImageUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // FIXED RENTAL ___ ADD IMAGE
    addImage: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: fixedRentalConfig.addImageUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // FIXED RENTAL ___ CHANGE STATUS
    changeStatus: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: fixedRentalConfig.changeStatusUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // FIXED RENTAL ___ REMOVE
    remove: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: fixedRentalConfig.removeUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },

    // FIXED RENTAL ___ SETTINGS
    settings: function (request) {
        return new Promise(function (resolve, reject) {
            axios({
                method: "POST",
                url: fixedRentalConfig.settingsUrl,
                data: request,
                withCredentials: true,
            })
                .then(res => {
                    try {
                        let resTemp = res.toJSON();
                        if (resTemp.name === 'Error') {
                            reject(resTemp);
                        }
                    } catch {
                        let response = res.data;
                        if (response.result === EXECUTE_RESULT.SUCCESS) {
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    }
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
}